<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      dense
      :sort-by.sync="peopleSortBy"
      :sort-desc.sync="sortDesc"
      :items-per-page="-1"
      :headers="headers"
      :items="people"
      :search="search"
    >
    <template v-slot:[`item.name`]="{ item }">
       <strong>{{ item.name }}</strong>
    </template>
    <template v-slot:[`item.telephone`]="{ item }">
       <a :href="`tel:${item.telephone}`">{{ item.telephone }}</a>
    </template>
    </v-data-table>
  </v-card>
</template>

<script>
  export default {
    props: {
    people: Array,
  },

  data() {
    return {
      search: "",
      headers: [
        {
          text: "Name",
          align: "start",
          filterable: true,
          value: "name",
        },
        { text: "Telephone", value: "telephone" },
        { text: "Room Number", value: "room_number" },
        { text: "Floor", value: "floor" },
      ],
      peopleSortBy: "name",
      sortDesc: false,
    };
  },
  }
</script>