<template>
  <v-card>
    <v-toolbar dark flat :color="'primary'">
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

      <v-toolbar-title>Rubrica Reception</v-toolbar-title>

      <v-spacer></v-spacer>

      <template v-slot:extension>
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider color="success"></v-tabs-slider>

          <v-tab :color="'secondary'" v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-snackbar
      v-if="errorText"
      v-model="permSnackbar"
      color="error"
      timeout="4000"
      top
      absolute
    >
      {{ errorText }}
      <template v-slot:action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="permSnackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item">
        <v-card flat>
          <AllView v-show="items[tab] == 'All'" :all="all" :loading="loading"/>
          <PeopleView v-show="items[tab] == 'People'" :people="people"/>
          <RoomsView v-show="items[tab] == 'Rooms'" :rooms="rooms"/>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import AllView from "./AllView.vue";
import PeopleView from "./PeopleView.vue";
import RoomsView from "./RoomsView.vue";

export default {
  components: {
    AllView,
    PeopleView,
    RoomsView,
  },

  data() {
    return {
      tab: null,
      loading: true,
      permSnackbar: true,
      errorText: "",
      items: ["All", "People", "Rooms"],
      data: null,

      // Data mixed (rooms and people)
      all: [],

      // Only people
      people: [],

      // Only rooms
      rooms: [],
    };
  },
  methods: {
    // Fetch rooms via API
    async fetchData() {
      this.loading = true;
      this.permSnackbar= true;
      this.errorText = "";
      try {
        let response = await this.$http.get(
          process.env.VUE_APP_API_ENDPOINT + "/allusers",
          {
            headers: {},
          }
        );
        this.data = response.data;
        
        this.parseData();
        //console.log(this.data);
      } catch (err) {
        if (err.message.includes("Network Error")) {
          this.errorText = "Risorsa riservata - Accesso bloccato"
          console.log(err);
        } else {
        this.errorText = err.message;
        this.loading= false,
        console.log(err);
        }
      }
    },


    // Split people and rooms in several data utils used by components
    parseData() {
      
      // If data about rooms are retrieved then add rooms in all and rooms
      if (this.data.Rooms) {
        for (let r of this.data.Rooms) {
          
          let entry = {}
          entry.name = r.Name;

          if(r.Tel){
            entry.telephone = "+39 02 503" + r.Tel;
          }
          else{
            entry.telephone = ''
          }

          entry.room_number = r.Room


          // Extract floor using the first element of the string representing the room number
          // entry.floor = r.Room[0]
          entry.floor = r.Floor;

          this.all.push(entry);
          this.rooms.push(entry);

        }
      }
      // If data about structured people
      if (this.data.Structured){

        for (let s of this.data.Structured) {
          
          let entry = {}

          entry.name = s.SecondName + ' ' + s.FirstName;

          if(s.Tel){
            entry.telephone = "+39 02 503" + s.Tel;
          }
          else{
            entry.telephone = ''
          }

          entry.room_number = s.Room

          // Extract floor using the first element of the string representing the room number
          // entry.floor = s.Room[0]
          entry.floor = s.Floor;

          this.all.push(entry);
          this.people.push(entry);

        }

      }

      // If data about NonStructured
      if (this.data.NonStructured){
        for (let n of this.data.NonStructured) {
          
          let entry = {}

          entry.name = n.SecondName + ' ' + n.FirstName;

          if(n.Tel){
            entry.telephone = "+39 02 503" + n.Tel;
          }
          else{
            entry.telephone = ''
          }

          entry.room_number = n.Room

          // Extract floor using the first element of the string representing the room number
          // entry.floor = n.Room[0]
          entry.floor = n.Floor;

          this.all.push(entry);
          this.people.push(entry);

        }
      }

      this.loading = false;


    },
  },

  created: async function () {
    await this.fetchData();
  },
};
</script>