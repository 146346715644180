<template>
    <v-row v-if="loading" justify="center">
      <v-col cols="2">
        <br />
        <br />
        <br />
        <v-progress-circular
          color="primary"
          indeterminate
          :size="70"
          :width="7"
        />
        <br />
        <br />
        <br />
      </v-col>
    </v-row>
    <v-card v-else>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        dense
        :sort-by.sync="allSortBy"
        :sort-desc.sync="sortDesc"
        :items-per-page="-1"
        :headers="headers"
        :items="all"
        :search="search"
      >
        <template v-slot:[`item.name`]="{ item }">
          <strong>{{ item.name }}</strong>
        </template>
        <template v-slot:[`item.telephone`]="{ item }">
          <a :href="`tel:${item.telephone}`">{{ item.telephone }}</a>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
export default {
  props: {
    all: Array,
    loading: Boolean,
  },

  data() {
    return {
      search: "",
      headers: [
        {
          text: "Name",
          align: "start",
          filterable: true,
          value: "name",
        },
        { text: "Telephone", value: "telephone" },
        { text: "Room Number", value: "room_number" },
        { text: "Floor", value: "floor" },
      ],
      allSortBy: "name",
      sortDesc: false,
    };
  },

  methods: {},

  created: function () {},
};
</script>