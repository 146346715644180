<template>
  <v-app>
    <v-app-bar app :color="testMode ? 'red' : 'primary'" dark>
        <v-row align="center">
          <v-col class="d-flex ma-0 pa-0" cols="2" >
            <v-img :src="require('@/assets/logo_dip_dark.png')" > </v-img>
          </v-col>
          <h1 v-if="testMode">TEST MODE !!!</h1>
          <v-spacer></v-spacer>
          <v-col class="d-flex" cols="2">
            <h3>{{ timestamp }}</h3>
          </v-col>
        </v-row>      
    </v-app-bar>

    <v-main>
      <v-row>
          <v-col>
            <RubricaIndex class="ma-3" />
          </v-col>
        </v-row>
    </v-main>

    <v-dialog
      v-model="privacy"
      width="800"
      scrollable
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Privacy
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Informativa Privacy
        </v-card-title>

        <v-card-text>
          <strong>Premessa</strong><br>
Ai sensi dell’art. 13 del Regolamento (UE) 2016/679 (Regolamento Generale sulla Protezione dei Dati o RGPD), l’Università degli Studi di Milano (nel seguito indicata anche come “Ateneo”) nella persona del Rettore pro tempore, informa gli utenti in merito all’utilizzo dei dati personali che li riguardano da parte del sito web https://reception.di.unimi.it (di seguito anche “sito”).
La presente informativa è limitata al solo sito https://reception.di.unimi.it e non ha valore per i siti esterni anche se accessibili a partire da link presenti su https://reception.di.unimi.it.
Resta ferma l’osservanza da parte dell’Università degli Studi di Milano della vigente normativa in materia di trasparenza e di pubblicazione obbligatoria di dati e documenti.<br>
<strong>1. Titolare del trattamento e Responsabile per la protezione dei dati (RPD)</strong><br>
Titolare del trattamento dei dati è l’Università degli Studi di Milano, nella persona del Rettore pro tempore, via Festa del Perdono n. 7, 20122 Milano, e-mail infoprivacy@unimi.it. Ai sensi degli artt. 37 e seguenti del Regolamento UE 2016/679, l’Ateneo ha nominato il Responsabile per la protezione dei dati (RPD) contattabile all’indirizzo e-mail dpo@unimi.it.
Per informazioni o approfondimenti sull’utilizzo dei dati personali trattati durante la navigazione e l'interazione con il sito, contattare il Direttore del Dipartimento di Informatica “Giovanni degli Antoni” all’indirizzo e-mail direttore@di.unimi.it.<br>
<strong>2. Finalità e base giuridica del trattamento</strong><br>
Vengono elencati, di seguito, i dati che verranno trattati:
<ul>
<li>recapiti telefonici, numero e piano dell’ ufficio</li>
<li>dati di navigazione. A titolo d’esempio: indirizzo IP, tipo di browser e parametri del dispositivo usato per connettersi al sito, nome dell’internet service provider (ISP), data ed orario di visita, pagina web di provenienza del visitatore (referral) e di uscita;</li>
</ul>
Nel rispetto della vigente normativa in materia di protezione dei dati personali, i recapiti telefonici e di stanza contenuti nella presente sezione sono messi a disposizione degli utenti esclusivamente per attività connesse alle finalità istituzionali dell'Ateneo.
Le suddette informazioni sono trattate in forma automatizzata e raccolte in forma aggregata al fine di verificare il corretto funzionamento del sito e per motivi di sicurezza. Tali informazioni saranno trattate in base al legittimo interesse del titolare.
Ai fini di sicurezza (filtri antispam, firewall, rilevazione virus), i dati registrati automaticamente possono eventualmente comprendere dati personali come l’indirizzo IP, che potrebbe essere utilizzato, in conformità alla legge vigente, al fine di bloccare tentativi di danneggiamento al sito o di recare danno ad altri utenti, o comunque attività dannose o costituenti reato. In ogni caso tali dati non verranno mai utilizzati a fini di profilazione dell’utenza del sito ma solo a fini di tutela del sito e dei suoi utenti.
Le basi giuridiche del trattamento sono, quindi: l’ottemperanza a obblighi di legge e contrattuali, l’adempimento di specifiche richieste dell’interessato prima della conclusione del contratto e il trattamento dei dati connesso alla gestione di eventuali reclami o contenziosi e per la prevenzione e repressione di frodi e di qualsiasi attività illecita, nonché l’adempimento di un compito di interesse pubblico.
<br>
<strong>3. Tipi di dati trattati</strong><br>
I Dati Personali sono raccolti per le finalità descritte sopra ed utilizzano piattaforme fornite nell’ambito dell’Ateneo. I sistemi informatici e le procedure applicative preposte al funzionamento del sito acquisiscono, nel corso del loro normale esercizio, alcuni dati la cui trasmissione è garantita dall’uso dei protocolli di comunicazione sulla rete intranet ed internet.
Tali informazioni possono essere utilizzate per ricavare informazioni statistiche anonime sull’uso del portale e per controllarne il corretto funzionamento e non sono associate a utenti identificati; tuttavia per loro natura e mediante associazioni con dati trattati da terzi, potrebbero consentire l’identificazione degli interessati. Rientra in questa categoria, ad esempio, l’indirizzo IP del sistema utilizzato per collegarsi al portale.
Questi dati vengono rimossi dai sistemi dopo l’elaborazione delle statistiche e sono conservati off-line esclusivamente per accertamenti di responsabilità in caso di reati informatici e consultabili solo su richiesta dell’autorità giudiziaria. <br>
<strong>4. Facoltatività del conferimento dei dati</strong><br>
La consultazione volontaria del sito comporta automaticamente la concessione dei dati di connessione; il soggetto che visita il sito esprime il consenso al trattamento dei dati, consapevole che in assenza di tale consenso non sarà possibile usufruire del servizio richiesto. I trattamenti effettuati prima della revoca del consenso da parte dell’interessato resteranno comunque validi.<br>
<strong>5. Modalità del trattamento</strong><br>
La raccolta dei dati avviene nel rispetto dei principi di pertinenza, completezza e non eccedenza in relazione ai fini per i quali sono trattati.
I dati personali conferiti sono trattati in osservanza dei principi di liceità, correttezza e trasparenza, previsti dall’articolo 5 RGPD, anche con l’ausilio di strumenti informatici e telematici atti a memorizzare e gestire i dati stessi, e comunque in modo tale da garantirne la sicurezza e tutelare la massima riservatezza dell’interessato.
I dati potrebbero essere oggetto di trattamento in forma anonima per lo svolgimento di attività statistiche finalizzate al miglioramento dei servizi offerti.<br>
<strong>6. Utilizzo di cookie</strong><br>
I cookie sono file di testo che vengono depositati sui computer degli utenti web per consentire l’esplorazione sicura ed efficiente del sito e monitorarne l’utilizzo.
Il sito https://reception.di.unimi.it non utilizza cookie tecnici, cookie di monitoraggio e cookie di terze parti.
Non vengono utilizzati cookie con esplicite finalità di profilazione o per finalità differenti da quelle qui dichiarate.<br>

<strong>7. Categorie di soggetti autorizzati al trattamento e ai quali i dati possono essere comunicati</strong><br>
I dati di connessione degli utenti saranno conosciuti e trattati, nel rispetto della vigente normativa in materia, dal personale del Dipartimento (individuati come Autorizzati al trattamento) coinvolto nella manutenzione del sito.
I dati potranno essere comunicati:
a) alle strutture dell’Ateneo che ne facciano richiesta, per le finalità istituzionali dell’Ateneo o in osservanza di obblighi legislativi;
b) a soggetti pubblici non economici o consorzi partecipati dall’Ateneo (ad es. MIUR) quando la comunicazione è necessaria per lo svolgimento di funzioni istituzionali dell’ente richiedente;
c) ad alcuni soggetti esterni, individuati come Responsabili del trattamento ex art. 28 RGPD;
È fatta salva, in ogni caso, la comunicazione o diffusione di dati richiesti, in conformità alla legge, dall’Autorità di Pubblica Sicurezza, dall’Autorità Giudiziaria o da altri soggetti pubblici per finalità di difesa, sicurezza dello Stato ed accertamento dei reati, nonché la comunicazione all’Autorità Giudiziaria in ottemperanza ad obblighi di legge, laddove si ravvisino ipotesi di reato.
Non saranno, infine, trasferiti dati personali verso Paesi terzi od organizzazioni internazionali a meno che ciò non sia strettamente connesso a richieste specifiche provenienti dall’utente o a necessità legate alla finalizzazione dell’intervento, per le quali si acquisirà apposito consenso.<br>
<strong>8. Conservazione dei dati</strong><br>
In relazione alle diverse finalità e agli scopi per i quali sono stati raccolti, i dati saranno conservati per il tempo previsto dalla normativa di riferimento ovvero per quello strettamente necessario al perseguimento delle finalità. Nello specifico:
i dati di navigazione verranno conservati per un massimo di 5 anni;<br>

<strong>9. Diritti dell’Interessato</strong><br>
Gli interessati hanno il diritto di ottenere dal Garante, nei casi previsti, l'accesso ai propri dati personali e la rettifica o la cancellazione degli stessi o la limitazione del trattamento che li riguarda o di opporsi al trattamento (artt. 15 e ss. del Regolamento).
L’istanza è presentata contattando il Responsabile della protezione dei dati (Responsabile della Protezione dei Dati, via Festa del Perdono, 7, 20122 Milano - e-mail: dpo@pec.unimi.it).<br>
<strong>10. Diritto di reclamo</strong><br>
Gli interessati che ritengono che il trattamento dei dati personali a loro riferiti effettuato attraverso questo sito avvenga in violazione di quanto previsto dal Regolamento, hanno il diritto di proporre reclamo al Garante, come previsto dall'art. 77 del Regolamento stesso, o di adire le opportune sedi giudiziarie (art. 79 del Regolamento).<br>
<strong>11. Modifiche alle Informazioni</strong><br>
Le presenti informazioni potrebbero subire delle modifiche nel tempo. Si consiglia, pertanto, di verificare, nella sezione Privacy del sito web https://reception.di.unimi.it, che la versione a cui ci si riferisce sia la più aggiornata.


        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="redirect('https://www.unimi.it')"
          >
            Non Accetto
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="privacy = false"
          >
            Accetto
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    
  </v-app>
</template>

<script>
import RubricaIndex from './components/RubricaIndex';

// My beauty date formatter
import { BeautyDateTime } from "./utils/BeautyDate";

export default {
  name: 'App',

  components: {
    RubricaIndex,
  },

  data: () => ({
    //
    timestamp: "",
    testMode: false,
    privacy: false,
  }),

  methods: {

    getNow() {
      this.timestamp = BeautyDateTime(new Date());
    },
    redirect(url) {
      window.location.href = url;
    },
  },

  mounted: function () {
    
    console.log("Prestito App Version: " + process.env.VUE_APP_VERSION);
    console.log("Prestito App TestMode: " + process.env.VUE_APP_TESTMODE);
    
    this.getNow();
    setInterval(this.getNow, 5000);

     // Read env variable and convert to bool
    this.testMode = process.env.VUE_APP_TESTMODE=='true';

  }
};
</script>
